.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #eeeeee;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.body{
  margin: 0px;
}

.heroLanding{
  position: relative;
  display: inline-block;
}


.confetti{
  padding-left: 55px;
  display: block;
  width: 100%;
  height: auto;
}

.herobannertext{
  position: absolute;
  top: 60%; 
  left: 50%; 
  transform: translate(-50%, -50%); 
  padding: 10px 10px;  
  font-size: 10px;
  text-align: center;
}

@media (min-width: 800px) {
  .herobannertext {
    font-size: 100%;
  }
}

.learningmethodText{
  width: 100%;
}

.sideImg1{
  padding-left: 50px;
}

.sideImg2{
  padding-left: 50px;
}
      
.sideImg3{
  padding-left: 50px;
}
        
.footer{
  padding-left: 25px;
}

.align-items-center2{
  justify-content: center;
}
.getstartedButton{
  background-color: #fa0e16;
  border: none;
  border-radius: 10px;
  color: white;
  padding: 10px 30px;
  font-size: 30px;
  cursor: pointer;
}